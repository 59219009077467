import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1920,
		},
	},
	spacing: 8,
	palette: {
		primary: {
			light: "#80a1f2ff",
			main: "#5D8BD4ff",
			dark: "#2773eeff",
		},
		secondary: {
			main: "#5DD46A",
		},
		text: {
			primary: "#002d5b",
		},
	},
	typography: {
		allVariants: {
			color: "#002d5b",
			fontFamily: ["Montserrat", "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
		},
		h5: {
			color: "#002d5b",
			fontSize: "1rem",
			fontWeight: "800",
		},
		h4: {
			color: "#002d5b",
			fontSize: "1rem",
		},
		h3: {
			color: "#002d5b",
			fontSize: "1.5rem",
		},
		h2: {
			color: "#002d5b",
			fontSize: "2rem",
		},
		h1: {
			margin: "0",
			fontSize: "3rem",
			fontWeight: 400,
			lineHeight: 1.167,
			letterSpacing: "0em",
			color: "#5D8BD4ff",
		},
		body1: {
			lineHeight: "2rem",
			fontSize: "1.2rem",
			color: "#002d5b",
		},
	},
	shape: {
		borderRadius: 8,
	},
	mixins: {
		toolbar: {
			minHeight: 48,
		},
	},

	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				input: {
					padding: "12.5px 14px",
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					"&:before": {
						border: "none !important",
					},
					"&:after": {
						border: "none",
					},
					"&:active": {
						border: "none",
					},
					"&:hover": {
						"&:not": {
							border: "none",
						},
					},
					"&:hover:not:before": {
						border: "none",
					},

					border: "none",
				},
			},
		},
		// Name of the component
		MuiButton: {
			styleOverrides: {
				disableElevation: true,
				root: {
					borderRadius: "8px",
					"&:hover": {
						color: "#74AAFF",
						// backgroundColor: '#dc4c5411',
					},
					color: "#5D8BD4",
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: "none",
					"&:hover": {
						color: "#5DD46A",
						textDecoration: "none",
					},
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				root: {
					zIndex: 1000,
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				indicator: {
					backgroundColor: "transparent",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "none",
					minWidth: "60px",
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					color: "#002d5b",
				},
			},
		},
	},
});
