/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, CircularProgress, Container, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { fontWeight, SxProps } from "@mui/system";
import React, { FC, useContext } from "react";
import { JdContext } from "../helper/JdContext";
import { theme } from "../helper/JdTheme";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { NavigationLinks } from "../helper/NavigationLinks";

//**********************************************************************************************
// INTERFACE
//**********************************************************************************************
export interface IHeaderProps {
	visible?: boolean;
}

//**********************************************************************************************
// COMPONENT
//**********************************************************************************************
export const Header: FC<IHeaderProps> = (props: IHeaderProps) => {
	const context = useContext(JdContext);
	const { visible } = props;
	const navigate = useNavigate();

	// Hamburger
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	//**********************************************************************************************
	// STYLE
	//**********************************************************************************************
	const sxSticky: SxProps = {
		position: "fixed",
		top: 0,
		left: 0,
		backgroundColor: "#ffffff",
		height: "100px",
		zIndex: 999999,
		width: "100%",
		opacity: 0.9,
	};

	const sxButton = {
		color: "#002d5b",
		marginRight: "0px",
		paddingRight: "0px",
		fontWeight: 700,
		"&:hover": {
			color: theme.palette.primary.main,
			background: "none",
		},
	};

	const sxMenu = {
		zIndex: 9999992,
		"& .MuiMenu-paper": {
			borderRadius: "0px",
			width: "150px",
		},
		"& .MuiMenu-paper:before": {
			marginRight: "8px",
		},
		"& .MuiMenuItem-root": {
			// paddingTop: "18px",
			fontSize: "0.875rem",
		},
	};

	const sxBlurHeader = {
		position: "fixed",
		top: 0,
		left: 0,
		height: "100px",
		width: "100%",
		// backgroundColor: "rgba(255, 255, 255, 0.5)",
		webkitBackdropFilter: "blur(4px)",
		oBackdropFilter: "blur(4px)",
		mozBackdropFilter: "blur(4px)",
		backdropFilter: "blur(4px)",
	};

	function onNavigate(link: string) {
		navigate(link);
	}

	//**********************************************************************************************
	// RENDER
	//**********************************************************************************************
	return (
		<React.Fragment>
			{!context.mediaXS && !context.mediaSM && (
				<React.Fragment>
					<Box sx={sxBlurHeader}></Box>
					<Box sx={sxSticky}>
						<Container maxWidth="lg" sx={{ display: "flex" }}>
							<Box pl={0} flexGrow={1} sx={{ paddingTop: "20px" }}>
								<Typography variant="h1" component={"p"} sx={{ display: "inline" }}>
									Jozsef
								</Typography>
								<Typography variant="h1" component={"p"} sx={{ display: "inline", color: theme.palette.primary.main, fontWeight: "800" }}>
									David
								</Typography>
							</Box>
							<Box pr={4} sx={{ lineHeight: "100px" }}>
								<Box sx={{ display: "flex" }}>
									<Box pr={2}>
										<Button disableRipple sx={sxButton} onClick={() => onNavigate(NavigationLinks.HOME)}>
											HOME
										</Button>
									</Box>
									<Box pr={2}>
										<Button disableRipple sx={sxButton} onClick={() => onNavigate(NavigationLinks.PRODUCTS_ALL_PRODUCTS)}>
											PRODUCTS
										</Button>
									</Box>
									<Box pr={2}>
										<Button disableRipple sx={sxButton} onClick={() => onNavigate(NavigationLinks.RESUME)}>
											RESUME
										</Button>
									</Box>
									<Box pr={2}>
										<Button disableRipple sx={sxButton} onClick={() => onNavigate(NavigationLinks.CONTACT)}>
											CONTACT
										</Button>
									</Box>
									<Box>
										<Button disableRipple sx={sxButton} onClick={() => onNavigate(NavigationLinks.MENTORING)}>
											MENTORSHIP
										</Button>
									</Box>
								</Box>
							</Box>
						</Container>
					</Box>
				</React.Fragment>
			)}
			{(context.mediaXS || context.mediaSM) && (
				<React.Fragment>
					<Box sx={sxBlurHeader}></Box>
					<Grid container sx={{ padding: "22px", position: "fixed", top: 0, left: 0, backgroundColor: "#ffffff", zIndex: "9999991", opacity: 0.9 }}>
						<Grid item xs={6}>
							<Typography variant="h1" component={"p"} sx={{ display: "inline" }}>
								Jozsef
							</Typography>
							<Typography variant="h1" component={"p"} sx={{ display: "inline", color: theme.palette.primary.main, fontWeight: "800" }}>
								David
							</Typography>
						</Grid>
						<Grid item xs={6} sx={{ textAlign: "right", paddingTop: "10px" }}>
							<Button disableRipple sx={sxButton} id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
								<MenuIcon fontSize="large" />
							</Button>
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
								sx={sxMenu}
								PaperProps={{
									elevation: 0,
									sx: {
										overflow: "visible",
										filter: "drop-shadow(0px 0px 4px rgba(0,0,0,0.32))",
										mt: 1.5,
										"& .MuiAvatar-root": {
											width: 32,
											height: 32,
											ml: -0.5,
											mr: 1,
										},
										"&:before": {
											content: '""',
											display: "block",
											position: "absolute",
											top: 0,
											right: 14,
											width: 10,
											height: 10,
											bgcolor: "background.paper",
											transform: "translateY(-50%) rotate(45deg)",
											zIndex: 0,
										},
									},
								}}
								transformOrigin={{ horizontal: "right", vertical: "top" }}
								anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
							>
								<MenuItem onClick={() => onNavigate(NavigationLinks.HOME)}>HOME</MenuItem>
								<MenuItem onClick={() => onNavigate(NavigationLinks.PRODUCTS_ALL_PRODUCTS)}>PRODUCTS</MenuItem>
								<MenuItem onClick={() => onNavigate(NavigationLinks.RESUME)}>RESUME</MenuItem>
								<MenuItem onClick={() => onNavigate(NavigationLinks.CONTACT)}>CONTACT</MenuItem>
								<MenuItem onClick={() => onNavigate(NavigationLinks.MENTORING)}>MENTORSHIP</MenuItem>
							</Menu>
						</Grid>
					</Grid>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
